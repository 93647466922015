import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import ImageEight from '../components/images/image-eight'
import ImageNine from '../components/images/image-nine'

import Layout from '../components/layout'
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody, }from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import SEO from '../components/seo'

const Label = styled.label`
display: block;
margin-top: .75rem;
`
const Grid = styled.div`
 display: grid;
 grid-template-columns: 1fr;
 grid-gap: 20px;

 > div > div:first-child {
    margin-bottom: 2rem;
    border-width: 1px solid;
    background: rgba(255, 255, 255, 0.30);
    padding: 10px;
 }

 h4 {
   padding: 5px;
 }

 h4:nth-child(2n) {
    background: #c5bba469;
}

  ul {
    list-style: initial;
    margin-left: 3rem;
  }

 @media ( max-width: 900px) {
   grid-template-columns: 100%;
   
   ul {
    margin-left: 1rem;
   }
 }

`

const CheckBox = styled.input`
  margin-left: 2rem;
`

const H3 = styled.h3`
  position: relative;
  font-size: 1rem;
  margin-bottom: 0;
`
const AccordionTitle = styled(AccordionItemTitle)`
  padding: 1rem;
  background-color: #c5bba469;
  margin-bottom:1px;
`
const Alert = styled.div`
 border: solid 2px orange;
 padding: .750rem;
 text-align: center;
 h4 {
   margin-bottom: 0;
 }
 span {
   color: orange;
   
 }
 margin-bottom: .750rem;
`

class EsApropiadoPage extends React.Component {

  state = { count: 0 }

  onclick(type){
    this.setState(prevState => {
       return { count: type === 'add' ? prevState.count + 1: prevState.count - 0 }
    });
}
  render(){
    return (
  <Layout>
    <SEO title="es Apropiado para mi el Metodo Mckensie " />
    <Grid>
    <h2>Autotratamiento para el Dolor de Espalda</h2>
    <div>
      <div>
      
      <h4>
      ¿Hay algún momento en el día en el cuál no tengas dolor? ¿ Aunque sean sólo 10 minutos?
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿El dolor se encuentra localizado en una zona por encima de la rodilla o por encima del codo?
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')}  /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿Has tenido más de un episodio de dolor lumbar, dolor de cuello o dolor articular en los últimos meses o años?
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿Te encuentras peor durante o justo después de estar agachado? Por ejemplo, cuando haces la cama, pasas el aspirador, cuando haces algo en el jardín, etc.
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿Te encuentras peor cuando estás sentado un rato o sientes dolor y rigidez al levantarte del sofá, después de conducir, después de estar delante del ordenador...?
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿Asocias tu dolor con una actividad concreta? ¿ tienes síntomas cuando no estás realizando esa actividad?
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿Estás peor cuando estás inactivo y mejor cuando te mueves?
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿Te encuentras mejor cuando te tumbas boca abajo? (si sientes dolor nada más ponerte boca abajo pero en un minuto el dolor se va, en este caso la respuesta debe ser si)
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      <h4>
      ¿Te encuentras mejor cuando caminas?
      <Label>
      <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'add')} /> Sí <CheckBox type="checkbox" onChange={this.onclick.bind(this, 'sub')} /> No
      </Label>
      </h4>
      </div>
      <Alert style={ this.state.count >= 2 ? {display:`block`} : { display: `none` } } >
      <h4>Tu Puntaje es <span> {this.state.count} </span> deberías contactar con un Fisioterapeuta Acreditado McKenzie</h4></Alert>
      <p>Si has contestado Sí a más de 4 preguntas la posibilidad de beneficiarte del tratamiento con el Método McKenzie son muy buenas. Si tu respuesta es Sí a 3 preguntas o menos deberías contactar con un Fisioterapeuta Acreditado McKenzie o a un profesional médico ya que necesitas que te guíen en el tratamiento de tus síntomas.</p>

      <ImageEight /> 

      <h2>Si has sufrido cualquiera de estos problemas:</h2>
      <ul>
        <li>Ciática</li>
        <li>Dolor tipo artrósico en cadera o nalgas, en hombros o extremidad superior</li>
        <li>Dificultad para agacharte tanto por rigidez como por dolor</li>
        <li>Un dolor que empieza en la zona lumbar o en el cuello que parece que irradia o se va hacia las nalgas o los hombros</li>
        <li>Si has tenido o tienes sensación intermitente de pinchazos y hormigueos o adormecimiento en pies o manos</li>
        <li>Dolor en el codo o rodilla que no ha respondido al tratamiento local de estas áreas</li>
        </ul>
        <p>Entonces debes ser tratado por un Fisioterapeuta Acreditado McKenzie. Estos fisioterapeutas están formados para evaluar y diagnosticar todas las áreas del sistema músculo-esquelético. Los fisioterapeutas MDT están formados para evaluar tu patología y enseñarte como puedes aprender a ayudarte por ti mismo, a mejorar tu dolor, rigidez o mejor la capacidad para realizar tu trabajo o tu actividad cotidiana.</p>

        <ImageNine />

        <h2>Preguntas Frecuentes</h2>
        <Accordion>
          <AccordionItem>
              <AccordionTitle>
                  <H3>¿Qué es Diagnóstico y Terapia Mecánica?
                    <div className="accordion__arrow" role="presentation" />
                  </H3>
              </AccordionTitle>
              <AccordionItemBody>
                  <p>El Método McKenzie de Diagnóstico y Terapia Mecánica (MDT) es un método de evaluación y tratamiento para la columna lumbar, cervicales y extremidades desarrollado por el fisioterapeuta neozelandés Robin McKenzie. El método ha sido utilizado en todo el mundo durante los últimos 30 años. Es un abordaje educacional de terapia manual y movimiento que tiene como objetivo proporcionar al paciente las herramientas necesarias para que él mismo pueda autotratarse tanto a corto como a largo plazo ante una recaída. De MDT se han realizado muchos estudios  científicos, dispone de una amplia evidencia científica tanto sobre el proceso de evaluación como sobre el tratamiento.</p>
              </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
              <AccordionTitle>
                  <H3>¿Cómo funciona?
                  <div className="accordion__arrow" role="presentation" />  
            </H3>
              </AccordionTitle>
              <AccordionItemBody>
             <p>Evaluación</p>

              <p>El fisioterapeuta le hará una serie de preguntas sobre sus síntomas y a continuación le pedirá que haga una serie de movimientos. En base a las respuestas sintomáticas a estos movimientos específicos y las posturas que le habrán pedido que adopte, el fisioterapeuta obtendrá la información necesaria para llegar a una conclusión diagnóstica y diseñar un programa de tratamiento específico para usted.</p>

              <p>Tratamiento</p>

              <p>Usando la información de la evaluación, si el fisioterapeuta lo encuentra adecuado, prescribirá unos ejercicios específicos y aconsejará determinadas posturas que se deben adoptar y otras que se deben evitar. Si el problema o patología tiene una presentación mecánica más compleja, un Fisioterapeuta Acreditado en el Método McKenzie puede aplicar técnicas manuales 'avanzadas' hasta que el paciente sea capaz de autotratarse a través de ejercicios específicos y con la corrección de la postura.</p>

              <p>El objetivo es solucionar el problema lo antes posible con el mínimo número de sesiones en la clínica . El tratamiento prescrito se debe realizar en casa cinco o seis veces al día, así se obtiene un resultado mucho más efectivo que con el tratamiento convencional de fisioterapia. Normalmente se realizan una o dos sesiones a la semana. Lo importante es que el paciente se involucre en el tratamiento de una forma activa para conseguir que se recupere de sus dolencias más rápidamente.</p>

              <p>Se ha comprobado que con este método la mayoría de los pacientes puede autotratarse con éxito si se les da las herramientas y conocimientos necesarios para poder hacerlo. El fisioterapeuta McKenzie se encargará de dárselas.</p>

              <p>Prevención</p>

              <p>Aprendiendo como tratar la dolencia actual el paciente va a ser capaz de minimizar los riesgos de una recaída y sabrá qué pauta debe seguir si los síntomas aparecen de nuevo. Le enseñaremos a reconocer y controlar los síntomas de manera rápida y efectiva. En los últimos estudios se ha comprobado que los problemas recidivantes pueden ser prevenidos a través de los ejercicios personalizados de mantenimiento que el fisioterapeuta McKenzie le enseñará.</p>
              </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿Cuáles son los beneficios del paciente con MDT/Método McKenzie?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <ul>
                <li>La mayoría de pacientes que responden favorablemente a MDT puede autotratarse con éxito, reduciendo sus sesiones en consulta, si se les da las herramientas y el conocimiento necesario para que tengan el control de su problema de una manera efectiva y segura.</li>
                <li>Los pacientes que siguen el protocolo de tratamiento tienen una menor tendencia a tener recidivas.</li>
                <li>Adquirir conocimientos sobre como minimizar el riesgo de recidivas y como abordar el problema de una forma rápida si los síntomas vuelven a aparecer.</li>
                <li>Los objetivos del Método McKenzie son:
                  <ul>
                    <li>Reducir dolor y deformidad</li>
                    <li>Mantener la reducción con recomendaciones posturales y educación</li>
                    <li>Restaurar función completa y</li>
                    <li>Prevenir recaídas</li>
                  </ul>
                </li>
            </ul>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿Todos los fisioterapeutas usan este Método?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <p>No.  Muchos fisioterapeutas no están formados en este método, lo que parece sorprendente debido a su efectividad y su evidencia  científica. Desafortunadamente, muchos que dicen que “hacen McKenzie” han modificado y diluido el Método en gran parte por lo que apenas se asemeja al verdadero Método. Sin una adecuada evaluación y formación, los resultados suelen ser malos. Los Fisioterapeutas Acreditados y Diplomados han pasado los exámenes necesarios que demuestran tener la habilidad y conocimiento necesario que aporta el método McKenzie.</p>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿Cómo sé si el fisioterapeuta ha sido formado adecuadamente en el Método?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <p>Los fisioterapeutas que han completado el programa de formación completo y han pasado el examen de acreditación obtendrá el título de Fisioterapeuta Acreditado MDT. Esto acredita el nivel básico de competencia. Otros fisioterapeutas pueden haber realizado el Diploma que es el nivel más alto de competencia.
              </p>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿El Método McKenzie implica Electroterapia?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <p>No</p>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿El Método McKenzie MDT solo es para problemas de dolor de espalda y cuello?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <p>No, también es muy efectivo en el tratamiento de músculos, tendones y alteraciones articulares a nivel periférico como puede ser hombro, cadera, etc. </p>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿Qué me harán en la primera visita?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
            <ul>
              <li>Serás evaluado por un fisioterapeuta competente</li>
              <li>Se te explicará la naturaleza del problema</li>
              <li>Se te explicará que pueden hacer para ayudarte</li>
              <li>Se te explicará que puedes hacer tú para solucionar el problema</li>
            </ul>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿Me puede ayudar si tengo el dolor de cuello o espalda desde hace años?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <p>Muy probablemente. Las investigaciones han demostrado que este Método puede tratar de manera efectiva los pacientes que presentan dolor desde hace meses e incluso años. Gracias a la evaluación que se te realizará  durante las primeras dos/tres sesiones el fisioterapeuta podrá determinar qué beneficio puedes obtener utilizando el Método McKenzie.</p>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿ Dónde puedo encontrar información sobre como llegar a ser un Fisioterapeuta Acreditado y participar en los cursos de formación?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <p>Para más información sobre nuestros cursos y formarte como Fisioterapeuta Acreditado visita por favor nuestra pagina de <a href="https://fisio.cr" target="_blank" alt={`pagina-de-fisio`} rel="noopener noreferrer" >educación/formación</a></p>
            </AccordionItemBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionTitle>
              <H3>¿Quién es Robin McKenzie?
              <div className="accordion__arrow" role="presentation" /> 
            </H3>
            </AccordionTitle>
            <AccordionItemBody>
              <p>Robin McKenzie fue un fisioterapeuta de Nueva Zelanda que concibió el Método de Diagnóstico y Terapia Mecánica a lo largo de muchos años. Internacionalmente reconocido, fue el fundador y Presidente del Instituto Internacional McKenzie. El Instituto Internacional McKenzie es una organización internacional sin ánimo de lucro de educación e investigación que fue fundada para desarrollar esta filosofía de tratamiento. Hay delegaciones en más de 30 países en todo el mundo para acometer la creciente demanda de conocimiento tanto por parte de los fisioterapeutas como de los pacientes.</p>
            </AccordionItemBody>
          </AccordionItem>
      </Accordion>
      <Link to="/agendar-cita/" className={`mdt-button-simple`}>Agendar una Cita</Link>
    </div>
  </Grid>
  </Layout>
)
}
}

export default EsApropiadoPage
