import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImageEight = () => (
  <StaticQuery
    query={graphql`
      query {
        imageEight: file(relativePath: { eq: "mdt-5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1366) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.imageEight.childImageSharp.fluid} alt={`mdt-5`}/>}
  />
)
export default ImageEight
